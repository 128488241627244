import Glide from '@glidejs/glide'

class HomeSlider {
  initiates() {
    new Glide(
      '.glide',
      {
        type: 'slider',
				bound: true,
        perView: 3,
        gap: 50,
        breakpoints: {
          1400: {
            perView: 3,
            gap: 40
          },
          1200: {
            perView: 2,
            gap: 20
          },
          600: {
            perView: 1,
            gap: 10
          }
        }
      }
    ).mount()
  }
}

export default new HomeSlider();
